import React from "react";
import Websites from "./Websites";
import "./style.scss";

function SectFourMob() {
  return (
    <>
      <div className="sec-4-mob-row row-1">
        <Websites name="pura" />
      </div>
      <div className="sec-4-mob-row row-2">
        <Websites name="formare" />
        <Websites name="etiqueta" />
      </div>
      <div className="sec-4-mob-row row-3">
        <Websites name="tt" />
        <Websites name="plasta" />
      </div>
    </>
  );
}

export default SectFourMob;
