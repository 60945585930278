import "./App.css";
import Header from "./components/navbar/Navbar";
import Home from "./home/Home";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
    </div>
  );
}

export default App;
