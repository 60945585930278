import React from "react";
import "./style.scss";
import me from "../../assets/paolo-me.png";
import arr from "../../assets/arrow-sec-2.png";

function SectTwo() {
  return (
    <>
      <div className="left-col-2">
        <img src={me} alt="paolo spinelli" className="me-ill" />
      </div>
      <div className="right-col-2">
        <div className="text-container">
          <p>
            naples-based <span> software developer </span> <br /> co-founder of{" "}
            <br /> <span> p.u.r.a. digital </span>
            softwarehouse, <br /> keen on 3D AND <span>DIGITAL ART</span>.
          </p>
        </div>
        <div className="right-col-2-arrow">
          <img src={arr} alt="arrow" />
          <p>
            check out my <span> skills</span>{" "}
          </p>
        </div>
      </div>
      <div className="gradient-cover"></div>
    </>
  );
}

export default SectTwo;
