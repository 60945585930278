import React, { useState } from "react";
import { email_costants } from "../../COSTANTS";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";
import emailjs from "emailjs-com";


import "./style.scss";
function SectFive() {
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [messaggio, setMessaggio] = useState("");

  function allEmpty() {
    setNome("");
    setEmail("");
    setMessaggio("");
  }

  function sendEmail(e) {
    e.preventDefault();

    setShowLoading(true);

    emailjs
      .sendForm(
        email_costants.emailjs_serviceID,
        email_costants.emailjs_templateID,
        e.target,
        email_costants.form
      )
      .then((res) => {
        console.log(res);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);

        allEmpty();
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
        allEmpty();
        setShowLoading(false);
      });
  }

  return (
    <>
      <div>
        <h3>
          Do you need my help to develop something? <br /> Let's get in touch!
        </h3>
        <h4>Fill the form below</h4>
        <div className="container-contacts">
          <div className="left-col">
            <p>
              <span> email:</span> spinopaolo@gmail.com
            </p>
            <p>
              {" "}
              <span>instagram:</span> spino.paolo
            </p>
          </div>
          <div className="right-col">
            <form onSubmit={sendEmail}>
              <input
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                value={nome}
                name="name"
                type="text"
                required
                placeholder="NAME"
              />
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                name="email"
                type="text"
                required
                placeholder="EMAIL"
              />
              <textarea
                onChange={(e) => {
                  setMessaggio(e.target.value);
                }}
                value={messaggio}
                name="messaggio"
                type="text"
                required
                placeholder="TELL ME SOMETHING ABOUT YOUR IDEA"
              />
              {showLoading ? (
                <button>
                  <Spinner animation="border" variant="light" />
                </button>
              ) : (
                <button>Submit</button>
              )}
            </form>
          </div>
        </div>
      </div>
      <div class="toast-div">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          className="toast-custom"
        >
          <Toast.Header className="toast-head">
            <strong className="me-auto">Mail Sent!</strong>
          </Toast.Header>
          <Toast.Body className="toast-body">
            Your email has been sent successfully!
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default SectFive;
