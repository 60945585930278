import React from "react";
import "./style.scss";

import ai from "./logos/ai.png";
import blender from "./logos/blender.png";
import wp from "./logos/wp.png";
import react from "./logos/react.png";
import gsap from "./logos/gsap.png";
import strapi from "./logos/strapi.png";
import figma from "./logos/figma.png";
import git from "./logos/git.png";
import html from "./logos/html.png";
import css from "./logos/css.png";
import js from "./logos/js.png";
import nodejs from "./logos/nodejs.png";
import tailwind from "./logos/tailwind.png";
import framer from "./logos/framer.png";
import sass from "./logos/sass.png";

function Skill({ name }) {
  if (name === "html") {
    return (
      <div className="group-skills">
        <img src={html} alt="html css javascript" />
        <div className="html">
          <span>Html</span>
        </div>
      </div>
    );
  }

  if (name === "css") {
    return (
      <div className="group-skills">
        <img src={css} alt="html css javascript" />
        <div className="html">
          <span>CSS</span>
        </div>
      </div>
    );
  }

  if (name === "js") {
    return (
      <div className="group-skills">
        <img src={js} alt="html css javascript" />
        <div className="html">
          <span>Javascript</span>
        </div>
      </div>
    );
  }

  if (name === "nodejs") {
    return (
      <div className="group-skills">
        <img src={nodejs} alt="html css javascript" />
        <div className="html">
          <span>Node.js</span>
        </div>
      </div>
    );
  }

  if (name === "react") {
    return (
      <div className="group-skills">
        <img src={react} alt="react" className="react-logo" />
        <div>
          <span>React.js</span>
        </div>
      </div>
    );
  }

  if (name === "strapi") {
    return (
      <div className="group-skills">
        <img src={strapi} alt="strapi" />
        <div>
          <span>Strapi</span>
        </div>
      </div>
    );
  }

  if (name === "wp") {
    return (
      <div className="group-skills">
        <img src={wp} alt="wordpress" />
        <div>
          <span>Wordpress</span>
        </div>
      </div>
    );
  }

  if (name === "figma") {
    return (
      <div className="group-skills">
        <img src={figma} alt="figma" />
        <div>
          <span>Figma</span>
        </div>
      </div>
    );
  }

  if (name === "gsap") {
    return (
      <div className="group-skills">
        <img src={gsap} alt="gsap" />
        <div>
          <span>GSAP</span>
        </div>
      </div>
    );
  }

  if (name === "blender") {
    return (
      <div className="group-skills">
        <img src={blender} alt="blender" />
        <div>
          <span>Blender</span>
        </div>
      </div>
    );
  }

  if (name === "ai") {
    return (
      <div className="group-skills">
        <img src={ai} alt="illustrator adobe" />
        <div>
          <span>Illustrator</span>
        </div>
      </div>
    );
  }

  if (name === "git") {
    return (
      <div className="group-skills">
        <img src={git} alt="git" />
        <div>
          <span>Git</span>
        </div>
      </div>
    );
  }

  if (name === "tailwind") {
    return (
      <div className="group-skills">
        <img src={tailwind} alt="tailwind css" />
        <div>
          <span>TailwindCss</span>
        </div>
      </div>
    );
  }

  if (name === "framer") {
    return (
      <div className="group-skills">
        <img src={framer} alt="framer motion" />
        <div>
          <span>Framer Motion</span>
        </div>
      </div>
    );
  }

  if (name === "framer") {
    return (
      <div className="group-skills">
        <img src={sass} alt="sass" />
        <div>
          <span>SASS</span>
        </div>
      </div>
    );
  }
}

export default Skill;
