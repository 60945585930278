import React from "react";
import Skill from "./Skill";
import "./style.scss";

function SectThree() {
  return (
    <>
      <div className="sec-3">
        <h2>Skills</h2>
        <div className="grid-skills">
          <Skill name="react" />
          <Skill name="html" />
          <Skill name="css" />
          <Skill name="tailwind" />
          <Skill name="sass" />
          <Skill name="js" />
          <Skill name="strapi" />
          <Skill name="nodejs" />
          <Skill name="wp" />
          <Skill name="git" />
          <Skill name="gsap" />
          <Skill name="framer" />
          <Skill name="figma" />
          <Skill name="blender" /> 
          <Skill name="ai" />


        </div>
      </div>
    </>
  );
}

export default SectThree;
