import React from "react";
import Websites from "./Websites";
import "./style.scss";

function SectFour() {
  return (
    <>
      <div className="sec-4-row-1">
        <Websites name="pura" />
        <Websites name="formare" />
        <Websites name="etiqueta" />
      </div>
      <div className="sec-4-row-2">
        <Websites name="tt" />
        <Websites name="plasta" />
      </div>
    </>
  );
}

export default SectFour;
