import React, { useEffect, useState } from "react";
import "./style.scss";
import Lottie from "react-lottie";
import scrollData from "../../assets/scroll-down.json";
import Spline from "../../components/spline-blob/Spline";

function SectOne() {
  const [lottieScroll, setLottieScroll] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: scrollData,
    // rendererSettings: {
    //   preserveAspectRatio: "contain",
    // },
  };

  useEffect(() => {
    setTimeout(() => {
      setLottieScroll(true);
    }, 1000);
  }, []);

  return (
    <>
      <div className="left-col">
        <div className="left-col-presentation">
          <p>Hi, My name </p>
          <p>
            is
            <span className="my-name">Paolo</span>
          </p>
        </div>
        <div className="left-col-check-portfolio">
          <p>Take a look at my</p>
          <span>Portfolio</span>
        </div>
      </div>
      <div className="right-col">
        <div className="spline-blob">
          {/* {window.innerWidth > 792 && <Spline />} */}
        </div>
      </div>
      {lottieScroll && (
        <div className="scroll-down-an">
          <Lottie options={defaultOptions} height={30} width={30} />
          <span>scroll down</span>
        </div>
      )}
    </>
  );
}

export default SectOne;
