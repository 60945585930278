import React from "react";
import SectOne from "./section-one/SectOne";
import SectTwo from "./section-two/SectTwo";
import SectThree from "./section-three/SectThree";
import SectFour from "./section-four/SectFour";
import SectFive from "./section-five/SectFive";
import SectThreeMob from "./section-three/SectThreeMob";
import SectFourMob from "./section-four/SectFourMob";

function Home() {
  return (
    <div className="container-home">
      <section className="section-1-container">
        <SectOne />
      </section>
      <section id="about" className="section-2-container">
        <SectTwo />
      </section>

      <section id="skill" className="section-3-container">
        {window.innerWidth > 720 && <SectThree />}
        {window.innerWidth < 720 && <SectThreeMob />}
      </section>

      <section id="project" className="section-4-container">
        {window.innerWidth > 720 && <SectFour />}
        {window.innerWidth < 720 && <SectFourMob />}
      </section>

      <section id="contact" className="section-5-container">
        <SectFive />
      </section>
    </div>
  );
}

export default Home;
