import React from "react";

function Websites({ name }) {
  if (name === "formare") {
    return (
      <div className="websites">
        <div>
          <h3>Poloformare.it</h3>
          <p>Wordpress</p>
        </div>
        <a href="https://www.poloformare.it/">Link to the website</a>
      </div>
    );
  }

  if (name === "pura") {
    return (
      <div className="websites">
        <div>
          <h3>Puradigital.it</h3>
          <p>React.js</p>
          <p>GSAp</p>
        </div>
        <a href="https://puradigital.it/">Link to the website</a>
      </div>
    );
  }

  if (name === "etiqueta") {
    return (
      <div className="websites">
        <div>
          <h3>etiquetalatina.com</h3>
          <p>Woocommerce</p>
          <p>Wordpress</p>
        </div>
        <a href="http://etiquetalatina.com/">Link to the website</a>
      </div>
    );
  }

  if (name === "tt") {
    return (
      <div className="websites">
        <div>
          <h3>Tuttesatte.it</h3>
          <p>React.js</p>
          <p>Wordpress</p>
        </div>
        <a href="https://www.tuttesatte.it/">Link to the website</a>
      </div>
    );
  }

  if (name === "plasta") {
    return (
      <div className="websites">
        <div>
          <h3>Plastarei.it</h3>
          <p>Wordpress</p>
        </div>
        <a href="plastarei.it">Link to the website</a>
      </div>
    );
  }
}

export default Websites;
