import React from "react";
import "./style.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/logo.png";

function Header() {
  return (
    <header className="main-header">
      <Navbar collapseOnSelect expand="lg" className="header-nav">
        <Navbar.Brand href="/">
          <img src={logo} alt="logo spinopaolo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="nav-list">
          <Nav >
            <div className="main-header-row-menu">
              <Nav.Link className="main-header-links" href="#about">
                About me
              </Nav.Link>
              <Nav.Link className="main-header-links" href="#skill">
                Skills
              </Nav.Link>
              <Nav.Link className="main-header-links" href="#project">
                Projects
              </Nav.Link>
              <Nav.Link className="main-header-links" href="#contact">
                Contacts
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
